import React from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorBoundary = () => {
    const error = useRouteError();

    return (
        <div className="min-h-screen bg-gray-900 flex items-center justify-center px-4">
            <div className="max-w-lg w-full bg-gray-800 rounded-lg shadow-xl p-8">
                <h1 className="text-3xl font-bold text-red-500 mb-4">Oops! Something went wrong</h1>
                <p className="text-gray-300 mb-4">
                    {error?.message || "An unexpected error occurred"}
                </p>
                <div className="bg-gray-900 p-4 rounded-md">
                    <pre className="text-sm text-gray-400 whitespace-pre-wrap">
                        {error?.stack || "No error details available"}
                    </pre>
                </div>
                <button
                    onClick={() => window.location.reload()}
                    className="mt-6 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
                >
                    Reload Page
                </button>
            </div>
        </div>
    );
};

export default ErrorBoundary;
