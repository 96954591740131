// src/pages/ProjectRequestPage.js
import React from 'react';
import { Button } from '../components/ui/button';
import { Card } from '../components/ui/card';

const ProjectRequestPage = () => {
    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        company: '',
        projectType: '',
        description: '',
        budget: '',
        timeline: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Add your form submission logic here
            console.log('Form submitted:', formData);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="min-h-screen bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <Card className="bg-gray-800 border-gray-700">
                    <div className="px-6 py-8">
                        <h1 className="text-3xl font-bold text-white mb-8 text-center">
                            Project Request Form
                        </h1>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-300">Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md bg-gray-700 border border-gray-600 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-300">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md bg-gray-700 border border-gray-600 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-300">Company</label>
                                <input
                                    type="text"
                                    name="company"
                                    value={formData.company}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md bg-gray-700 border border-gray-600 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-300">Project Type</label>
                                <select
                                    name="projectType"
                                    value={formData.projectType}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md bg-gray-700 border border-gray-600 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    required
                                >
                                    <option value="">Select a project type</option>
                                    <option value="web">Web Development</option>
                                    <option value="mobile">Mobile App</option>
                                    <option value="ai">AI/ML Solution</option>
                                    <option value="consulting">Consulting</option>
                                </select>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-300">Project Description</label>
                                <textarea
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    rows={4}
                                    className="mt-1 block w-full rounded-md bg-gray-700 border border-gray-600 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-300">Budget Range</label>
                                <select
                                    name="budget"
                                    value={formData.budget}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md bg-gray-700 border border-gray-600 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    required
                                >
                                    <option value="">Select budget range</option>
                                    <option value="small">$5,000 - $10,000</option>
                                    <option value="medium">$10,000 - $25,000</option>
                                    <option value="large">$25,000 - $50,000</option>
                                    <option value="enterprise">$50,000+</option>
                                </select>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-300">Timeline</label>
                                <select
                                    name="timeline"
                                    value={formData.timeline}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md bg-gray-700 border border-gray-600 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    required
                                >
                                    <option value="">Select timeline</option>
                                    <option value="urgent">Less than 1 month</option>
                                    <option value="short">1-3 months</option>
                                    <option value="medium">3-6 months</option>
                                    <option value="long">6+ months</option>
                                </select>
                            </div>

                            <Button
                                type="submit"
                                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-md transition duration-150 ease-in-out"
                            >
                                Submit Project Request
                            </Button>
                        </form>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default ProjectRequestPage;