import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from './context/ThemeContext';
import { AppProvider } from './context/AppContext';
import { Chart, registerables } from 'chart.js';

// Import pages
import HomePage from './pages/HomePage';
import MengieAIPage from './pages/MengieAIPage';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import ProjectRequestPage from './pages/ProjectRequestPage';
import DonationPage from './components/donation/DonationPage';
import ErrorBoundary from './components/ErrorBoundary';

// Register Chart.js components
Chart.register(...registerables);

// Configure routes with error boundaries and loading states
const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: "",
                element: <HomePage />,
                errorElement: <ErrorBoundary />
            },
            {
                path: "mengieai",
                element: <MengieAIPage />,
                errorElement: <ErrorBoundary />
            },
            {
                path: "services",
                element: <ServicesPage />,
                errorElement: <ErrorBoundary />
            },
            {
                path: "contact",
                element: <ContactPage />,
                errorElement: <ErrorBoundary />
            },
            {
                path: "project-request",
                element: <ProjectRequestPage />,
                errorElement: <ErrorBoundary />
            },
            {
                path: "donate",
                element: <DonationPage />,
                errorElement: <ErrorBoundary />
            }
        ]
    }
]);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <AppProvider>
            <ThemeProvider>
                <RouterProvider router={router} />
            </ThemeProvider>
        </AppProvider>
    </React.StrictMode>
);