import React from 'react';
import ContactForm from '../components/contact/ContactForm';

export const ContactPage = () => {
  return (
    <div className="bg-gray-900 min-h-screen text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">Contact Us</h1>
          <p className="text-xl text-gray-400">
            Get in touch with our team for inquiries and support
          </p>
        </div>

        <ContactForm />
      </div>
    </div>
  );
};
export default ContactPage;