import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Navbar } from './components/navigation/Navbar';
import { Footer } from './components/common/Footer';
import { Loading } from './components/common/Loading';
import { useApp } from './context/AppContext';

const App = () => {
    const location = useLocation();
    const { isLoading } = useApp();

    // Scroll to top on route change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
            {/* Loading Overlay */}
            {isLoading && (
                <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center">
                    <Loading />
                </div>
            )}

            {/* Main Layout */}
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <main className="flex-grow pt-16">
                    <Outlet />
                </main>
                <Footer />
            </div>
        </div>
    );
};

export default App;