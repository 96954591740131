import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../../components/ui/button';
import {
    Menu, X, ChevronDown, ExternalLink,
    BarChart2, Book, Mail, Gift
} from 'lucide-react';

export const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 10);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const menuItems = [
        { label: 'Home', path: '/' },
        { label: 'Services', path: '/services' },
        {
            label: 'Mengie AI',
            path: '/mengieai',
            isHighlighted: true,
            icon: <BarChart2 className="w-4 h-4" />
        },
        { label: 'Contact', path: '/contact' },
    ];

    return (
        <header
            className={`fixed w-full z-50 transition-all duration-200 ${
                isScrolled ? 'bg-gray-900/95 backdrop-blur-sm' : 'bg-transparent'
            }`}
        >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-20">
                    {/* Logo */}
                    <Link to="/" className="flex items-center space-x-2">
                        <span className="text-2xl font-bold text-white">Mengie</span>
                        <span className="text-blue-500 text-xs font-semibold py-0.5 px-2 rounded-full bg-blue-500/10">
              BETA
            </span>
                    </Link>

                    {/* Desktop Navigation */}
                    <nav className="hidden md:flex items-center space-x-8">
                        {menuItems.map((item) => (
                            <Link
                                key={item.path}
                                to={item.path}
                                className={`flex items-center space-x-1 px-3 py-2 rounded-lg transition-colors
                  ${location.pathname === item.path
                                    ? 'text-blue-400 bg-blue-500/10'
                                    : 'text-gray-300 hover:text-white'
                                }
                  ${item.isHighlighted ? 'font-medium' : ''}
                `}
                            >
                                {item.icon}
                                <span>{item.label}</span>
                            </Link>
                        ))}
                        <Button
                            onClick={() => window.location.href = '/donate'}
                            className="bg-blue-600 hover:bg-blue-700"
                        >
                            <Gift className="w-4 h-4 mr-2" />
                            Donate
                        </Button>
                    </nav>

                    {/* Mobile Menu Button */}
                    <button
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                        className="md:hidden p-2 rounded-lg text-gray-400 hover:text-white"
                    >
                        {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            {isMobileMenuOpen && (
                <div className="md:hidden bg-gray-900/95 backdrop-blur-sm">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        {menuItems.map((item) => (
                            <Link
                                key={item.path}
                                to={item.path}
                                onClick={() => setIsMobileMenuOpen(false)}
                                className={`flex items-center space-x-2 px-3 py-2 rounded-lg transition-colors
                  ${location.pathname === item.path
                                    ? 'text-blue-400 bg-blue-500/10'
                                    : 'text-gray-300 hover:text-white'
                                }
                `}
                            >
                                {item.icon}
                                <span>{item.label}</span>
                            </Link>
                        ))}
                        <Button
                            onClick={() => window.location.href = '/donate'}
                            className="w-full bg-blue-600 hover:bg-blue-700 mt-4"
                        >
                            <Gift className="w-4 h-4 mr-2" />
                            Donate
                        </Button>
                    </div>
                </div>
            )}
        </header>
    );
};

export const Footer = () => {
    const footerSections = [
        {
            title: 'Product',
            links: [
                { label: 'Features', path: '/mengieai' },
                { label: 'Enterprise', path: '/services' },
                { label: 'Security', path: '/security' },
                { label: 'Roadmap', path: '/roadmap' },
            ]
        },
        {
            title: 'Company',
            links: [
                { label: 'About', path: '/about' },
                { label: 'Contact', path: '/contact' },
                { label: 'Careers', path: '/careers' },
                { label: 'Blog', path: '/blog' },
            ]
        },
        {
            title: 'Resources',
            links: [
                { label: 'Documentation', path: '/docs' },
                { label: 'API Reference', path: '/api' },
                { label: 'Support', path: '/support' },
                { label: 'Status', path: '/status' },
            ]
        },
        {
            title: 'Legal',
            links: [
                { label: 'Privacy', path: '/privacy' },
                { label: 'Terms', path: '/terms' },
                { label: 'Security', path: '/security' },
                { label: 'Compliance', path: '/compliance' },
            ]
        }
    ];

    return (
        <footer className="bg-gray-900 border-t border-gray-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                    {footerSections.map((section) => (
                        <div key={section.title}>
                            <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-4">
                                {section.title}
                            </h3>
                            <ul className="space-y-3">
                                {section.links.map((link) => (
                                    <li key={link.label}>
                                        <Link
                                            to={link.path}
                                            className="text-gray-400 hover:text-white transition-colors"
                                        >
                                            {link.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                <div className="mt-12 pt-8 border-t border-gray-800">
                    <div className="flex flex-col md:flex-row items-center justify-between">
                        <div className="flex items-center space-x-2 mb-4 md:mb-0">
                            <span className="text-xl font-bold text-white">Mengie</span>
                            <span className="text-blue-500 text-xs font-semibold py-0.5 px-2 rounded-full bg-blue-500/10">
                BETA
              </span>
                        </div>
                        <div className="flex items-center space-x-6 text-gray-400">
                            <a
                                href="https://github.com/MEHDI342"
                                className="hover:text-white"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span className="sr-only">GitHub</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="currentColor"
                                    viewBox="0 0 24 24">
                                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                                </svg>
                            </a>
                            <a
                                href="https://linkedin.com/in/mehdi-el-jouhfi-93b99a2a5"
                                className="hover:text-white"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span className="sr-only">LinkedIn</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="currentColor"
                                    viewBox="0 0 24 24">
                                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="mt-4 text-center md:text-left text-gray-400 text-sm">
                        © {new Date().getFullYear()} Mengie. All rights reserved. Created by Mehdi El Jouhfi. Contact: midojouhfi@gmail.com
                    </div>
                </div>
            </div>
        </footer>
    );
};