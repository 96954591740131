import React from 'react';
import DonationForm from './DonationForm';

const DonationPage = () => {
    return (
        <div className="min-h-screen bg-gray-900 py-12 px-4">
            <div className="max-w-7xl mx-auto text-center">
                <h1 className="text-3xl font-bold text-white mb-8">
                    Support Our Project
                </h1>
                <p className="text-gray-400 mb-12 max-w-2xl mx-auto">
                    Your donation helps us continue developing and improving our financial analysis tools for everyone.
                </p>
                <DonationForm />
            </div>
        </div>
    );
};

export default DonationPage;