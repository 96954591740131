// src/components/ui/input.jsx
import React from "react";
import { cn } from "../../lib/utils";

const Input = React.forwardRef(({ className, type = "text", ...props }, ref) => {
    return (
        <input
            type={type}
            className={cn(
                "flex h-10 w-full rounded-md border border-gray-700 bg-gray-800",
                "px-3 py-2 text-sm text-white placeholder:text-gray-400",
                "focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-transparent",
                "disabled:cursor-not-allowed disabled:opacity-50",
                className
            )}
            ref={ref}
            {...props}
        />
    );
});

Input.displayName = "Input";

export { Input };