import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/tabs';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid,
  Tooltip, ResponsiveContainer, AreaChart, Area
} from 'recharts';
import {
  Activity, TrendingUp, BarChart2, Lock,
  AlertCircle, Globe, Database, Share2
} from 'lucide-react';

const MengieAIPage = () => {
  const [selectedTimeframe, setSelectedTimeframe] = useState('1D');

  const mockChartData = Array.from({ length: 20 }, (_, i) => ({
    time: i,
    value: 100 + Math.sin(i / 2) * 10 + Math.random() * 5,
    volume: Math.random() * 1000
  }));

  return (
      <div className="min-h-screen bg-gray-900 pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header Section */}
          <div className="py-12 mb-8">
            <h1 className="text-4xl font-bold text-white mb-4">
              Mengie AI Platform
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl">
              Advanced market analysis and predictions powered by artificial intelligence
            </p>
          </div>

          {/* Main Grid Layout */}
          <div className="grid grid-cols-12 gap-6">
            {/* Left Sidebar */}
            <div className="col-span-12 lg:col-span-3 space-y-6">
              <Card className="bg-gray-800 border-gray-700">
                <CardHeader>
                  <CardTitle className="text-white">Market Overview</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div>
                      <p className="text-sm text-gray-400">Market Status</p>
                      <div className="flex items-center space-x-2 text-green-400">
                        <Activity className="h-4 w-4" />
                        <span>Markets Open</span>
                      </div>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400">Global Sentiment</p>
                      <div className="flex items-center space-x-2 text-blue-400">
                        <TrendingUp className="h-4 w-4" />
                        <span>Bullish</span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card className="bg-gray-800 border-gray-700">
                <CardHeader>
                  <CardTitle className="text-white">Watchlist</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <WatchlistItem
                        symbol="AAPL"
                        price="182.63"
                        change="+1.24%"
                        sentiment="positive"
                    />
                    <WatchlistItem
                        symbol="MSFT"
                        price="390.27"
                        change="+0.89%"
                        sentiment="positive"
                    />
                    <WatchlistItem
                        symbol="GOOGL"
                        price="142.71"
                        change="-0.32%"
                        sentiment="neutral"
                    />
                  </div>
                </CardContent>
              </Card>
            </div>

            {/* Main Content */}
            <div className="col-span-12 lg:col-span-9 space-y-6">
              <Card className="bg-gray-800 border-gray-700">
                <CardHeader>
                  <div className="flex items-center justify-between">
                    <CardTitle className="text-white">Market Analysis</CardTitle>
                    <div className="flex space-x-2">
                      {['1D', '1W', '1M', '3M', 'YTD'].map((timeframe) => (
                          <Button
                              key={timeframe}
                              variant={selectedTimeframe === timeframe ? 'default' : 'outline'}
                              className={`${
                                  selectedTimeframe === timeframe
                                      ? 'bg-blue-600'
                                      : 'border-gray-700 hover:bg-gray-700'
                              }`}
                              onClick={() => setSelectedTimeframe(timeframe)}
                          >
                            {timeframe}
                          </Button>
                      ))}
                    </div>
                  </div>
                </CardHeader>
                <CardContent>
                  <div className="h-[400px]">
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart data={mockChartData}>
                        <defs>
                          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.3}/>
                            <stop offset="95%" stopColor="#3B82F6" stopOpacity={0}/>
                          </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                        <XAxis dataKey="time" stroke="#9CA3AF" />
                        <YAxis stroke="#9CA3AF" />
                        <Tooltip
                            contentStyle={{
                              backgroundColor: '#1F2937',
                              border: '1px solid #374151',
                              borderRadius: '0.375rem'
                            }}
                        />
                        <Area
                            type="monotone"
                            dataKey="value"
                            stroke="#3B82F6"
                            fillOpacity={1}
                            fill="url(#colorValue)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </CardContent>
              </Card>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <MetricsCard />
                <PredictionsCard />
              </div>
            </div>
          </div>

          {/* Additional Analysis Section */}
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
            <AnalysisCard
                icon={<Globe />}
                title="Global Markets"
                metrics={[
                  { label: "US Markets", value: "+1.2%" },
                  { label: "European Markets", value: "+0.8%" },
                  { label: "Asian Markets", value: "-0.3%" }
                ]}
            />
            <AnalysisCard
                icon={<Database />}
                title="Data Analysis"
                metrics={[
                  { label: "Data Points", value: "2.5M" },
                  { label: "Processing Rate", value: "50k/s" },
                  { label: "Accuracy", value: "94.2%" }
                ]}
            />
            <AnalysisCard
                icon={<Share2 />}
                title="Social Sentiment"
                metrics={[
                  { label: "News Sentiment", value: "Positive" },
                  { label: "Social Score", value: "78/100" },
                  { label: "Mentions", value: "12.5k" }
                ]}
            />
          </div>

          {/* Coming Soon Features */}
          <div className="mt-12 bg-gradient-to-r from-blue-900/20 to-indigo-900/20 rounded-lg p-8 border border-blue-800/50">
            <div className="flex items-start space-x-4">
              <div className="p-3 bg-blue-500/10 rounded-lg">
                <Lock className="h-6 w-6 text-blue-400" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-white mb-2">
                  Advanced Features Coming Soon
                </h3>
                <p className="text-gray-300 mb-4">
                  We're working on exciting new features including advanced technical analysis,
                  AI-powered trading signals, and institutional-grade portfolio analytics.
                </p>
                <Button className="bg-blue-600 hover:bg-blue-700">
                  Join Waitlist
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

const WatchlistItem = ({ symbol, price, change, sentiment }) => {
  const isPositive = change.startsWith('+');
  return (
      <div className="flex items-center justify-between">
        <div>
          <div className="font-medium text-white">{symbol}</div>
          <div className="text-sm text-gray-400">${price}</div>
        </div>
        <div className={`text-sm ${isPositive ? 'text-green-400' : 'text-red-400'}`}>
          {change}
        </div>
      </div>
  );
};

const MetricsCard = () => (
    <Card className="bg-gray-800 border-gray-700">
      <CardHeader>
        <CardTitle className="text-white">Key Metrics</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <MetricItem label="RSI (14)" value="65.4" status="neutral" />
          <MetricItem label="MACD" value="1.234" status="positive" />
          <MetricItem label="Volume" value="12.5M" status="positive" />
          <MetricItem label="Volatility" value="Low" status="negative" />
        </div>
      </CardContent>
    </Card>
);

const PredictionsCard = () => (
    <Card className="bg-gray-800 border-gray-700">
      <CardHeader>
        <CardTitle className="text-white">AI Predictions</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="p-4 bg-blue-900/20 border border-blue-800/50 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-300">Short-term Forecast</span>
              <span className="text-sm text-green-400">Bullish</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-300">Confidence Score</span>
              <span className="text-sm text-blue-400">87%</span>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="p-3 bg-gray-900 rounded-lg">
              <div className="text-sm text-gray-400">Price Target</div>
              <div className="text-lg font-bold text-white">$189.50</div>
            </div>
            <div className="p-3 bg-gray-900 rounded-lg">
              <div className="text-sm text-gray-400">Time Frame</div>
              <div className="text-lg font-bold text-white">7 Days</div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
);

const MetricItem = ({ label, value, status }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'positive': return 'text-green-400';
      case 'negative': return 'text-red-400';
      default: return 'text-blue-400';
    }
  };

  return (
      <div className="flex items-center justify-between">
        <span className="text-gray-300">{label}</span>
        <span className={getStatusColor(status)}>{value}</span>
      </div>
  );
};

const AnalysisCard = ({ icon, title, metrics }) => (
    <Card className="bg-gray-800 border-gray-700">
      <CardContent className="pt-6">
        <div className="flex items-center space-x-3 mb-4">
          <div className="p-2 bg-blue-500/10 rounded-lg">
            {React.cloneElement(icon, { className: "h-5 w-5 text-blue-400" })}
          </div>
          <h3 className="font-medium text-white">{title}</h3>
        </div>
        <div className="space-y-3">
          {metrics.map((metric, index) => (
              <div key={index} className="flex justify-between items-center">
                <span className="text-sm text-gray-400">{metric.label}</span>
                <span className="text-sm font-medium text-white">{metric.value}</span>
              </div>
          ))}
        </div>
      </CardContent>
    </Card>
);

export default MengieAIPage;