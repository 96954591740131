import React from 'react';
import { Button } from '../components/ui/button';
import { Card, CardContent } from '../components/ui/card';
import { BarChart2, Globe, Shield, Cpu, ArrowRight, Activity, Lock } from 'lucide-react';

const HomePage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
            {/* Hero Section */}
            <section className="pt-32 pb-24 px-4 relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10" />
                <div className="max-w-7xl mx-auto relative">
                    <div className="text-center mb-16">
                        <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 tracking-tight">
                            Next-Generation<br />
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600">
                Financial Intelligence
              </span>
                        </h1>
                        <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
                            Harness the power of artificial intelligence and real-time market data
                            to make informed investment decisions.
                        </p>
                        <div className="flex justify-center gap-4">
                            <Button
                                className="bg-blue-600 hover:bg-blue-700 text-lg px-8 py-6"
                                onClick={() => window.location.href='/mengieai'}
                            >
                                Try Mengie AI
                                <ArrowRight className="ml-2 h-5 w-5" />
                            </Button>
                            <Button
                                variant="outline"
                                className="border-gray-600 hover:bg-gray-800 text-lg px-8 py-6"
                                onClick={() => window.location.href='/contact'}
                            >
                                Contact Sales
                            </Button>
                        </div>
                    </div>

                    {/* Stats Section */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-24">
                        <StatCard
                            label="Assets Analyzed"
                            value="$2.5B+"
                            description="Daily market coverage"
                        />
                        <StatCard
                            label="Data Points"
                            value="500M+"
                            description="Processed daily"
                        />
                        <StatCard
                            label="Accuracy Rate"
                            value="94.2%"
                            description="In market predictions"
                        />
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section className="py-24 bg-gray-800/50">
                <div className="max-w-7xl mx-auto px-4">
                    <h2 className="text-3xl font-bold text-white text-center mb-16">
                        Enterprise-Grade Features
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <FeatureCard
                            icon={<Activity />}
                            title="Real-Time Analytics"
                            description="Get instant insights into market movements and trading opportunities."
                        />
                        <FeatureCard
                            icon={<Cpu />}
                            title="AI-Powered Predictions"
                            description="Machine learning models trained on decades of market data."
                        />
                        <FeatureCard
                            icon={<Globe />}
                            title="Global Coverage"
                            description="Analysis across all major markets and asset classes."
                        />
                        <FeatureCard
                            icon={<BarChart2 />}
                            title="Advanced Charting"
                            description="Professional-grade technical analysis tools."
                        />
                        <FeatureCard
                            icon={<Shield />}
                            title="Risk Management"
                            description="Sophisticated risk assessment and portfolio optimization."
                        />
                        <FeatureCard
                            icon={<Lock />}
                            title="Enterprise Security"
                            description="Bank-grade security and compliance measures."
                        />
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="py-24 bg-gradient-to-b from-gray-800 to-gray-900">
                <div className="max-w-4xl mx-auto text-center px-4">
                    <h2 className="text-3xl font-bold text-white mb-6">
                        Ready to Transform Your Investment Strategy?
                    </h2>
                    <p className="text-xl text-gray-300 mb-8">
                        Join leading institutions already using Mengie AI to stay ahead of the market.
                    </p>
                    <Button
                        className="bg-blue-600 hover:bg-blue-700 text-lg px-8 py-6"
                        onClick={() => window.location.href='/mengieai'}
                    >
                        Get Started Now
                        <ArrowRight className="ml-2 h-5 w-5" />
                    </Button>
                </div>
            </section>
        </div>
    );
};

const StatCard = ({ label, value, description }) => (
    <Card className="bg-gray-800/50 border-gray-700">
        <CardContent className="pt-6">
            <p className="text-gray-400 text-sm">{label}</p>
            <h3 className="text-3xl font-bold text-white my-2">{value}</h3>
            <p className="text-gray-500 text-sm">{description}</p>
        </CardContent>
    </Card>
);

const FeatureCard = ({ icon, title, description }) => (
    <Card className="bg-gray-800/50 border-gray-700 hover:border-blue-500/50 transition-all">
        <CardContent className="pt-6">
            <div className="text-blue-500 mb-4">
                {React.cloneElement(icon, { size: 24 })}
            </div>
            <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
            <p className="text-gray-400">{description}</p>
        </CardContent>
    </Card>
);

export default HomePage;