import React, { useState } from 'react';
import { Button } from '../common/Button';

const DonationForm = () => {
    const [amount, setAmount] = useState(6);
    const DONATION_AMOUNTS = [5, 10, 25, 50, 100, 5000000 ];

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle donation logic here
        console.log(`Processing donation of $${amount}`);
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-6">
            <div className="flex flex-wrap gap-3 justify-center">
                {DONATION_AMOUNTS.map((value) => (
                    <button
                        key={value}
                        type="button"
                        onClick={() => setAmount(value)}
                        className={`px-4 py-2 rounded-lg transition-colors ${
                            amount === value
                                ? 'bg-blue-600 text-white'
                                : 'bg-gray-700 hover:bg-gray-600 text-white'
                        }`}
                    >
                        ${value}
                    </button>
                ))}
            </div>

            <div className="bg-gray-800 p-4 rounded-lg border border-gray-700">
                <div className="text-center text-xl text-white font-bold">
                    ${amount}.00
                </div>
            </div>

            <Button
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700
                 transition-colors"
            >
                Donate Now
            </Button>
        </form>
    );
};

export default DonationForm;