import React, { createContext, useContext, useState } from 'react';
import { cn } from "../../lib/utils";

const TabsContext = createContext({
    selectedTab: null,
    setSelectedTab: () => null,
});

const Tabs = React.forwardRef(({ defaultValue, value, onValueChange, className, children, ...props }, ref) => {
    const [selectedTab, setSelectedTab] = useState(defaultValue || value);

    const handleTabChange = (newValue) => {
        if (onValueChange) {
            onValueChange(newValue);
        }
        if (!value) {
            setSelectedTab(newValue);
        }
    };

    return (
        <TabsContext.Provider value={{ selectedTab: value || selectedTab, setSelectedTab: handleTabChange }}>
            <div ref={ref} className={cn("w-full", className)} {...props}>
                {children}
            </div>
        </TabsContext.Provider>
    );
});
Tabs.displayName = "Tabs";

const TabsList = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        role="tablist"
        className={cn(
            "flex items-center justify-center rounded-lg bg-gray-800 p-1",
            className
        )}
        {...props}
    />
));
TabsList.displayName = "TabsList";

const TabsTrigger = React.forwardRef(({ value, className, children, ...props }, ref) => {
    const { selectedTab, setSelectedTab } = useContext(TabsContext);
    const isSelected = selectedTab === value;

    return (
        <button
            ref={ref}
            role="tab"
            aria-selected={isSelected}
            data-state={isSelected ? "active" : "inactive"}
            onClick={() => setSelectedTab(value)}
            className={cn(
                "inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1.5",
                "text-sm font-medium text-gray-300",
                "transition-all focus:outline-none focus:ring-2",
                "disabled:pointer-events-none disabled:opacity-50",
                isSelected ? "bg-blue-600 text-white shadow-sm" : "hover:bg-gray-700",
                className
            )}
            {...props}
        >
            {children}
        </button>
    );
});
TabsTrigger.displayName = "TabsTrigger";

const TabsContent = React.forwardRef(({ value, className, children, ...props }, ref) => {
    const { selectedTab } = useContext(TabsContext);
    const isSelected = selectedTab === value;

    if (!isSelected) return null;

    return (
        <div
            ref={ref}
            role="tabpanel"
            data-state={isSelected ? "active" : "inactive"}
            className={cn("mt-2", className)}
            {...props}
        >
            {children}
        </div>
    );
});
TabsContent.displayName = "TabsContent";

export { Tabs, TabsList, TabsTrigger, TabsContent };