import React from 'react';
import { Button } from '../components/ui/button';
import { Card, CardContent } from '../components/ui/card';
import {
  BarChart2, Database, Cloud,
  Shield, Code, Server,
  ExternalLink, ArrowRight
} from 'lucide-react';

const ServicesPage = () => {
  const services = [
    {
      icon: <BarChart2 className="h-6 w-6" />,
      title: "Financial Analysis",
      description: "AI-powered market analysis and prediction platform",
      features: [
        "Real-time market data analysis",
        "Machine learning predictions",
        "Sentiment analysis",
        "Technical indicators"
      ],
      primaryCTA: "Learn More",
      link: "/mengieai"
    },
    {
      icon: <Database className="h-6 w-6" />,
      title: "Data Engineering",
      description: "Enterprise-grade data infrastructure solutions",
      features: [
        "High-frequency data processing",
        "Custom data pipelines",
        "Real-time analytics",
        "Data warehousing"
      ],
      primaryCTA: "Explore Solutions",
      link: "/contact"
    },
    {
      icon: <Cloud className="h-6 w-6" />,
      title: "Cloud Infrastructure",
      description: "Scalable and secure cloud architecture",
      features: [
        "Multi-cloud deployment",
        "High-availability systems",
        "Performance optimization",
        "DevOps automation"
      ],
      primaryCTA: "See Details",
      link: "/contact"
    }
  ];

  const enterpriseSolutions = [
    {
      icon: <Code />,
      title: "Custom Development",
      description: "Tailored software solutions for your specific needs"
    },
    {
      icon: <Shield />,
      title: "Security & Compliance",
      description: "Enterprise-grade security and regulatory compliance"
    },
    {
      icon: <Server />,
      title: "Infrastructure",
      description: "Scalable and reliable infrastructure solutions"
    }
  ];

  return (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 pt-20">
        {/* Hero Section */}
        <section className="py-16 px-4">
          <div className="max-w-7xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Enterprise Solutions for
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600">
              {" "}Modern Finance
            </span>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-12">
              From AI-powered analytics to custom infrastructure solutions,
              we deliver enterprise-grade technology for the financial sector.
            </p>
          </div>
        </section>

        {/* Main Services */}
        <section className="py-16 px-4">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {services.map((service, index) => (
                  <Card key={index} className="bg-gray-800/50 border-gray-700">
                    <CardContent className="pt-6">
                      <div className="text-blue-500 mb-4">
                        {service.icon}
                      </div>
                      <h3 className="text-2xl font-bold text-white mb-3">
                        {service.title}
                      </h3>
                      <p className="text-gray-300 mb-6">
                        {service.description}
                      </p>
                      <ul className="space-y-3 mb-8">
                        {service.features.map((feature, idx) => (
                            <li key={idx} className="flex items-center text-gray-400">
                              <div className="h-1.5 w-1.5 rounded-full bg-blue-500 mr-2" />
                              {feature}
                            </li>
                        ))}
                      </ul>
                      <Button
                          className="w-full bg-blue-600 hover:bg-blue-700"
                          onClick={() => window.location.href = service.link}
                      >
                        {service.primaryCTA}
                        <ArrowRight className="ml-2 h-4 w-4" />
                      </Button>
                    </CardContent>
                  </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Enterprise Solutions */}
        <section className="py-16 px-4 bg-gray-800/50">
          <div className="max-w-7xl mx-auto">
            <h2 className="text-3xl font-bold text-white text-center mb-12">
              Enterprise Solutions
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {enterpriseSolutions.map((solution, index) => (
                  <Card key={index} className="bg-gray-900/50 border-gray-700">
                    <CardContent className="pt-6">
                      <div className="text-blue-500 mb-4">
                        {solution.icon}
                      </div>
                      <h3 className="text-xl font-bold text-white mb-2">
                        {solution.title}
                      </h3>
                      <p className="text-gray-400">
                        {solution.description}
                      </p>
                    </CardContent>
                  </Card>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-16 px-4">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-white mb-6">
              Ready to Transform Your Business?
            </h2>
            <p className="text-xl text-gray-300 mb-8">
              Let's discuss how our solutions can help you achieve your goals.
            </p>
            <Button
                className="bg-blue-600 hover:bg-blue-700 text-lg px-8 py-6"
                onClick={() => window.location.href='/contact'}
            >
              Schedule a Consultation
              <ExternalLink className="ml-2 h-5 w-5" />
            </Button>
          </div>
        </section>
      </div>
  );
};

export default ServicesPage;