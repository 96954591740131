import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { AlertCircle, Check } from 'lucide-react';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        projectType: '',
        description: '',
        budget: ''
    });

    const [status, setStatus] = useState({ type: '', message: '' });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus({ type: 'loading', message: 'Sending...' });

        try {
            // In production, replace with your actual email service endpoint
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    access_key: 'YOUR_WEB3FORMS_KEY',
                    subject: `New Project Request from ${formData.name}`,
                    from_name: formData.name,
                    to_email: 'midojouhfi@gmail.com',
                    message: `
            Name: ${formData.name}
            Email: ${formData.email}
            Company: ${formData.company}
            Project Type: ${formData.projectType}
            Budget: ${formData.budget}
            Description: ${formData.description}
          `
                }),
            });

            if (response.ok) {
                setStatus({ type: 'success', message: 'Message sent successfully!' });
                setFormData({
                    name: '',
                    email: '',
                    company: '',
                    projectType: '',
                    description: '',
                    budget: ''
                });
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            setStatus({ type: 'error', message: 'Failed to send message. Please try again.' });
        }
    };

    return (
        <div className="min-h-screen bg-gray-900 pt-20 pb-12">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold text-white mb-4">Let's Build Something Amazing</h1>
                    <p className="text-xl text-gray-400">
                        Tell us about your project and we'll get back to you within 24 hours
                    </p>
                </div>

                <Card className="bg-gray-800 border-gray-700">
                    <CardContent className="pt-6">
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                                <div>
                                    <label className="block text-sm font-medium text-gray-300 mb-2">
                                        Name
                                    </label>
                                    <Input
                                        type="text"
                                        required
                                        value={formData.name}
                                        onChange={(e) => setFormData({...formData, name: e.target.value})}
                                        className="bg-gray-700 border-gray-600 text-white"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-300 mb-2">
                                        Email
                                    </label>
                                    <Input
                                        type="email"
                                        required
                                        value={formData.email}
                                        onChange={(e) => setFormData({...formData, email: e.target.value})}
                                        className="bg-gray-700 border-gray-600 text-white"
                                    />
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-300 mb-2">
                                    Company
                                </label>
                                <Input
                                    type="text"
                                    value={formData.company}
                                    onChange={(e) => setFormData({...formData, company: e.target.value})}
                                    className="bg-gray-700 border-gray-600 text-white"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-300 mb-2">
                                    Project Type
                                </label>
                                <select
                                    value={formData.projectType}
                                    onChange={(e) => setFormData({...formData, projectType: e.target.value})}
                                    className="w-full bg-gray-700 border-gray-600 text-white rounded-md px-3 py-2"
                                    required
                                >
                                    <option value="">Select a project type</option>
                                    <option value="web">Web Development</option>
                                    <option value="mobile">Mobile App</option>
                                    <option value="ai">AI Solution</option>
                                    <option value="data">Data Analytics</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-300 mb-2">
                                    Budget Range
                                </label>
                                <select
                                    value={formData.budget}
                                    onChange={(e) => setFormData({...formData, budget: e.target.value})}
                                    className="w-full bg-gray-700 border-gray-600 text-white rounded-md px-3 py-2"
                                    required
                                >
                                    <option value="">Select budget range</option>
                                    <option value="5k-10k">$5,000 - $10,000</option>
                                    <option value="10k-25k">$10,000 - $25,000</option>
                                    <option value="25k-50k">$25,000 - $50,000</option>
                                    <option value="50k+">$50,000+</option>
                                </select>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-300 mb-2">
                                    Project Description
                                </label>
                                <textarea
                                    required
                                    value={formData.description}
                                    onChange={(e) => setFormData({...formData, description: e.target.value})}
                                    rows={4}
                                    className="w-full bg-gray-700 border-gray-600 text-white rounded-md px-3 py-2"
                                />
                            </div>

                            {status.message && (
                                <div className={`p-4 rounded-md ${
                                    status.type === 'success' ? 'bg-green-900/20 border border-green-800' :
                                        status.type === 'error' ? 'bg-red-900/20 border border-red-800' :
                                            'bg-blue-900/20 border border-blue-800'
                                }`}>
                                    <div className="flex items-center">
                                        {status.type === 'success' ? (
                                            <Check className="h-5 w-5 text-green-500 mr-2" />
                                        ) : (
                                            <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
                                        )}
                                        <p className="text-sm text-white">{status.message}</p>
                                    </div>
                                </div>
                            )}

                            <Button
                                type="submit"
                                disabled={status.type === 'loading'}
                                className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                            >
                                {status.type === 'loading' ? 'Sending...' : 'Submit Project Request'}
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default ContactForm;